var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "Cập nhật tin tức",
            visible: _vm.dialogVisible,
            "before-close": _vm.closeDialog,
            "close-on-click-modal": false,
            width: "76%",
            top: "10vh",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "updateData",
              attrs: {
                model: _vm.fromData,
                rules: _vm.rules,
                "label-width": "100px",
                "label-position": "left",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { prop: "title", label: "Tiêu đề" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "Nhập tiêu đề" },
                    model: {
                      value: _vm.fromData.title,
                      callback: function ($$v) {
                        _vm.$set(_vm.fromData, "title", $$v)
                      },
                      expression: "fromData.title",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "Nội dung", prop: "content" } },
                [
                  _c("el-input", {
                    attrs: {
                      rows: 10,
                      type: "textarea",
                      placeholder: "Nhập nội dung",
                    },
                    model: {
                      value: _vm.fromData.content,
                      callback: function ($$v) {
                        _vm.$set(_vm.fromData, "content", $$v)
                      },
                      expression: "fromData.content",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "link", label: "Đường dẫn" } },
                [
                  _c("el-input", {
                    attrs: {
                      placeholder:
                        "Nhập đường dẫn đính kèm (https://example.com)",
                    },
                    model: {
                      value: _vm.fromData.link,
                      callback: function ($$v) {
                        _vm.$set(_vm.fromData, "link", $$v)
                      },
                      expression: "fromData.link",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-upload",
                {
                  attrs: {
                    action: "/api/upload/",
                    "list-type": "picture-card",
                    "auto-upload": false,
                    "on-preview": _vm.handlePictureCardPreview,
                    "file-list": _vm.fromData.notifySchoolAttachFileList,
                    "on-remove": _vm.handleRemove,
                    "on-change": _vm.handleChange,
                    limit: 3,
                    "on-exceed": _vm.handleExceed,
                  },
                },
                [_c("i", { staticClass: "el-icon-plus" })]
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "danger", size: "medium" },
                  on: {
                    click: function ($event) {
                      return _vm.closeDialogByButton("updateData")
                    },
                  },
                },
                [
                  _c("i", { staticClass: "el-icon-circle-close" }),
                  _c("span", [_vm._v("Đóng")]),
                ]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    type: "success",
                    size: "medium",
                    loading: _vm.loadingButton,
                    mini: "",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.submitForm("updateData")
                    },
                  },
                },
                [
                  _c("i", { staticClass: "el-icon-circle-check" }),
                  _c("span", [_vm._v("Lưu")]),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        [
          _c(
            "el-dialog",
            {
              attrs: { width: "30%", visible: _vm.dialogVisible1 },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogVisible1 = $event
                },
              },
            },
            [
              _c("img", {
                attrs: { width: "100%", src: _vm.dialogImageUrl, alt: "" },
              }),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }