var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "Thêm mới tin tức",
            visible: _vm.dialogVisible,
            "before-close": _vm.closeDialog,
            "close-on-click-modal": false,
            width: "76%",
            top: "10vh",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "updateData",
              attrs: {
                model: _vm.fromData,
                rules: _vm.rules,
                "label-width": "100px",
                "label-position": "left",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { prop: "title", label: "Tiêu đề" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "Nhập tiêu đề" },
                    model: {
                      value: _vm.fromData.title,
                      callback: function ($$v) {
                        _vm.$set(_vm.fromData, "title", $$v)
                      },
                      expression: "fromData.title",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "Nội dung", prop: "content" } },
                [
                  _c("el-input", {
                    attrs: {
                      rows: 10,
                      type: "textarea",
                      placeholder: "Nhập nội dung",
                    },
                    model: {
                      value: _vm.fromData.content,
                      callback: function ($$v) {
                        _vm.$set(_vm.fromData, "content", $$v)
                      },
                      expression: "fromData.content",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "link", label: "Đường dẫn" } },
                [
                  _c("el-input", {
                    attrs: {
                      placeholder:
                        "Nhập đường dẫn đính kèm (https://example.com)",
                    },
                    model: {
                      value: _vm.fromData.link,
                      callback: function ($$v) {
                        _vm.$set(_vm.fromData, "link", $$v)
                      },
                      expression: "fromData.link",
                    },
                  }),
                ],
                1
              ),
              _c(
                "span",
                {
                  staticStyle: {
                    margin: "40px",
                    color: "orange",
                    "margin-top": "-30px",
                  },
                },
                [_vm._v("Chọn tối đa 3 ảnh")]
              ),
              _vm.showProgress
                ? _c("el-progress", {
                    staticStyle: {
                      width: "25%",
                      float: "left",
                      "margin-top": "30px",
                    },
                    attrs: { percentage: _vm.percentage, color: _vm.colors },
                  })
                : _vm._e(),
              _c(
                "el-upload",
                {
                  ref: "upload",
                  staticStyle: { "margin-left": "35px", clear: "both" },
                  attrs: {
                    action: "/api/upload/",
                    "list-type": "picture-card",
                    "auto-upload": false,
                    "file-list": _vm.multipartFileList,
                    "on-change": _vm.handleChangePicture,
                    "on-remove": _vm.handleRemove,
                    "before-remove": _vm.beforeRemove,
                    multiple: "",
                    limit: 3,
                    "on-exceed": _vm.handleExceed,
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "file",
                      fn: function ({ file }) {
                        return _c("div", {}, [
                          _c(
                            "span",
                            {
                              staticStyle: {
                                background: "orange",
                                padding: "5px",
                              },
                            },
                            [_vm._v("Mới")]
                          ),
                          _c("img", {
                            staticClass: "el-upload-list__item-thumbnail",
                            attrs: { src: file.url, alt: "" },
                          }),
                          _c(
                            "span",
                            { staticClass: "el-upload-list__item-actions" },
                            [
                              _c(
                                "span",
                                {
                                  staticClass: "el-upload-list__item-preview",
                                  on: {
                                    click: function ($event) {
                                      return _vm.handlePictureCardPreview(file)
                                    },
                                  },
                                },
                                [_c("i", { staticClass: "el-icon-zoom-in" })]
                              ),
                              _c(
                                "span",
                                {
                                  staticClass: "el-upload-list__item-delete",
                                  on: { click: _vm.handleRemove },
                                },
                                [_c("i", { staticClass: "el-icon-delete" })]
                              ),
                            ]
                          ),
                        ])
                      },
                    },
                  ]),
                },
                [
                  _c(
                    "i",
                    {
                      staticClass: "el-icon-plus",
                      staticStyle: {
                        "font-style": "normal",
                        "font-size": "24px",
                      },
                      attrs: { slot: "default" },
                      slot: "default",
                    },
                    [_vm._v("Thêm Ảnh")]
                  ),
                ]
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "danger", size: "medium" },
                  on: {
                    click: function ($event) {
                      return _vm.closeDialogByButton("updateData")
                    },
                  },
                },
                [
                  _c("i", { staticClass: "el-icon-circle-close" }),
                  _c("span", [_vm._v("Đóng")]),
                ]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    type: "success",
                    size: "medium",
                    loading: _vm.loadingButton,
                    mini: "",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.submitForm("updateData")
                    },
                  },
                },
                [
                  _c("i", { staticClass: "el-icon-circle-check" }),
                  _c("span", [_vm._v("Lưu")]),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { width: "30%", visible: _vm.dialogVisible1 },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible1 = $event
            },
          },
        },
        [
          _c("img", {
            attrs: { width: "100%", src: _vm.dialogImageUrl, alt: "" },
          }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }